export const environment = {
  production: '#__##production##__#',
  baseUrl: '#__##baseUrl##__#',
  showAds: '#__##showAds##__#',
  allowPush: '#__##allowPush##__#',
  iosAdUnit: '#__##iosAdUnit##__#',
  androidAdUnit: '#__##androidAdUnit##__#',
  updatePolling: '#__##updatePolling##__#',
  vapidPublic: '#__##vapidPublic##__#',
  isNative: '#__##isNative##__#'
};
