import { Injectable } from '@angular/core';
import {
  ActionSheetController,
  ActionSheetOptions,
  AlertController,
  LoadingController,
  ModalController,
  ModalOptions,
  Platform,
  ToastController
} from '@ionic/angular';
import { ComponentRef } from '@ionic/core';
import {NavigationExtras, Router} from "@angular/router";
import {Preferences} from "@capacitor/preferences";
import {settings} from "../enums/settings";

@Injectable({
  providedIn: 'root'
})
export class CoreFunctionsService {

  isLoading:boolean = false;

  private modalInstances:HTMLIonModalElement[]  | undefined;

  constructor(
    private readonly alertController: AlertController,
    private readonly toastController: ToastController,
    private readonly loadingController: LoadingController,
    private readonly modalController: ModalController,
    private readonly actionSheetController: ActionSheetController,
    private router: Router,
    private platform: Platform
  ) {
    this.modalInstances = [];
  }

  async showAlert(message: string, header?: string | undefined, buttonArray?: any, inputArray?: any)
  {
    await this.alertController.create({
      header: header ? header : 'Sweat and Sacrifice',
      message: message,
      buttons: buttonArray ? buttonArray : ['Ok'],
      inputs: inputArray
    })
      .then(alert => alert.present());
  }

  async showToast(message: string, color: any, position: any, duration = 3000)
  {
    const toastMessage = await this.toastController.create({
      message: message,
      duration: duration,
      color: color,
      position: position
    });

    await toastMessage.present();
  }

  async showSuccessToast(message: string)
  {
    const toastMessage = await this.toastController.create({
      message: message,
      color: 'success',
      position: 'top',
      duration: 3000
    });

    await toastMessage.present();
  }

  async showErrorToast(message: string)
  {
    const toastMessage = await this.toastController.create({
      message: message,
      color: 'danger',
      position: 'top',
      duration: 3000
    });

    await toastMessage.present();
  }

  async showLoader()
  {
    this.isLoading = true;
    return this.loadingController.create({
      message: 'Please Wait...',
      spinner: 'circular'
    }).then(res => {
      res.present().then(() => {
        if(!this.isLoading){
          res.dismiss();
        }
      });
    }).catch(e => {
      console.log('Loading Controller Error: ', e);
    });
  }

  async dismissLoader()
  {
    if(this.isLoading)
    {
      this.isLoading = false;
      return await this.loadingController.dismiss();
    }
    else{
      return;
    }
  }

  async createModal(options: ModalOptions<ComponentRef>, addToStack: boolean)
  {
    const modal = await this.modalController.create(options);

    if(addToStack)
      this.modalInstances!.push(modal);

    await modal.present();

    const {data} = await modal.onWillDismiss();

    if(data)
      return data;
  }

  async dismissModal(value?: any)
  {
    let data: any = value ? value : null;

    this.modalController.getTop().then(topModal => {
      this.modalController.dismiss(data).then(() =>{
        this.modalInstances = this.modalInstances?.filter(modalInstance => {
          return modalInstance != topModal;
        });
      });
    });
  }

  async dismissAllModals(value?: any) {
    let data: any = value ? value : null;

    this.modalInstances!.forEach((modal: HTMLIonModalElement) => {
      modal.dismiss(data).then(() => {
        this.modalInstances = this.modalInstances?.filter(modalInstance => {
          return modalInstance != modal;
        });
      });
    });

    this.modalInstances = [];
    await this.dismissLoader();
  }

  async showActionSheet(options: ActionSheetOptions){
    const actionSheet = await this.actionSheetController.create(options);

    await actionSheet.present();

    const result = await actionSheet.onDidDismiss();

    return result;
  }

  async logUserOut()
  {
    let navigationExtras: NavigationExtras = { state: { } };

    await this.showLoader();

    await Preferences.set({key: settings.BearerToken, value: ''});
    await Preferences.set({key: settings.EmailAddress, value: ''});
    await Preferences.set({key: settings.UserId, value: ''});
    await Preferences.set({key: settings.TestPopupSeen, value: 'false'});

    await this.router.navigate(['/login'], navigationExtras);

    await this.dismissLoader();
  }
}
