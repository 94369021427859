import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotificationSchema, PushNotifications,Token} from "@capacitor/push-notifications";
import {SettingsService} from "./settings.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private readonly settingsService: SettingsService) { }

  async initPushNotifications() {

    PushNotifications.addListener("registration", async (token: Token) => {
      if (token.value) {
        await this.settingsService.PushToken(token.value);
      }
    });

    PushNotifications.addListener("registrationError", (error: any) => {
      console.error('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener("pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener("pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        try {
          console.log('Push received: ' + JSON.stringify(notification));
        } catch (error) {
          console.error(error)
        }
      }
    );

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
      if (permStatus.receive === "granted") {
        await PushNotifications.register();
      } else {
        console.error('Got an error');
      }
    }
  }
}
