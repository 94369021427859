import {Injectable} from '@angular/core';
import {
  AdMob,
  AdMobBannerSize, AdmobConsentStatus, AdMobError,
  BannerAdOptions,
  BannerAdPluginEvents,
  BannerAdPosition,
  BannerAdSize
} from '@capacitor-community/admob';
import {Platform} from '@ionic/angular';
import {SettingsService} from "./settings.service";

@Injectable({
  providedIn: 'root'
})
export class AdMobService {
  adMobMargin:number = 0;
  bannerPosition: string = 'bottom';

  private bannerHeight: number = 0;

  constructor(private readonly platform: Platform,
              private readonly settingsService: SettingsService) {  }

  async initialize()
  {
    await AdMob.initialize({});

    const [trackingInfo, consentInfo] = await Promise.all([
      AdMob.trackingAuthorizationStatus(),
      AdMob.requestConsentInfo(),
    ]);

    if (trackingInfo.status === 'notDetermined') {
      await AdMob.requestTrackingAuthorization();
    }

    const authorizationStatus = await AdMob.trackingAuthorizationStatus();

    if (authorizationStatus.status === 'authorized' && consentInfo.isConsentFormAvailable && consentInfo.status === AdmobConsentStatus.REQUIRED) {
      await AdMob.showConsentForm();
    }
  }

  async showBannerAd()
  {
    let appId: string;

    if(this.platform.is('ios')){
      appId =  this.settingsService.iOSAdUnit();
    }else{
      appId = this.settingsService.AndroidAdUnit();
    }

    AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => this.bannerSizeChanged(size));
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => this.bannerAdLoaded());
    AdMob.addListener(BannerAdPluginEvents.FailedToLoad, (info: AdMobError) => this.bannerAdFailed(info))

    const options: BannerAdOptions = {
      adId: appId,
      adSize: BannerAdSize.ADAPTIVE_BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0,
      isTesting: !this.settingsService.Production(),
      npa: true
    };

    await AdMob.showBanner(options).catch(ex => console.error(ex));
  }

  async hideBannerAd()
  {
    const app = document.querySelector('ion-app')!;
    const body = document.querySelector('body')!;
    body.style.backgroundColor = 'transparent';
    app.style.marginBottom = `0px`;
    await AdMob.hideBanner();
  }

  async resumeBannerAd()
  {
    const app = document.querySelector('ion-app')!;
    const body = document.querySelector('body')!;
    body.style.backgroundColor = '#FF9944';
    app.style.marginBottom = `${this.bannerHeight}px`;
    await AdMob.resumeBanner();
  }

  bannerAdLoaded()
  {

  }

  bannerAdFailed(info: AdMobError)
  {
    console.log(info);
  }

  bannerSizeChanged(info: AdMobBannerSize)
  {
    const body = document.querySelector('body')!;
    const app = document.querySelector('ion-app')!;

    this.bannerHeight = info.height;

    if (this.bannerHeight > 0) {
      const bodyStyles = window.getComputedStyle(body);
      const safeAreaBottom = bodyStyles.getPropertyValue("--ion-safe-area-bottom");
      const tabBar: HTMLElement = document.querySelector('ion-tab-bar')!;

      if (this.bannerPosition === 'top') {
        app.style.marginTop = this.bannerHeight + 'px';
      } else {
        //app.style.marginBottom = `calc(${safeAreaBottom} + ${this.bannerHeight}px)`;
        app.style.marginBottom = `${this.bannerHeight}px`;
        tabBar.style.paddingBottom = '0px';
        body.style.backgroundColor = '#FF9944';
      }
    }
  }
}
