import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthenticationGuard} from "./guard/authentication.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full'
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.module').then( m => m.MainPageModule),
    canMatch: [AuthenticationGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'food-diary',
    loadChildren: () => import('./pages/food-diary/food-diary.module').then( m => m.FoodDiaryPageModule),
    canMatch: [AuthenticationGuard]
  },
  {
    path: 'exercise-diary',
    loadChildren: () => import('./pages/exercise-diary/exercise-diary.module').then( m => m.ExerciseDiaryPageModule),
    canMatch: [AuthenticationGuard]
  },
  {
    path: 'community',
    loadChildren: () => import('./pages/community/community.module').then( m => m.CommunityPageModule),
    canMatch: [AuthenticationGuard]
  },
  {
    path: 'more',
    loadChildren: () => import('./pages/more/more.module').then( m => m.MorePageModule),
    canMatch: [AuthenticationGuard]
  },
  {
    path: 'debug-info',
    loadChildren: () => import('./pages/debug-info/debug-info.module').then( m => m.DebugInfoPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule
    )
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
