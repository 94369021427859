import {Component, OnInit} from '@angular/core';
import { register } from 'swiper/element/bundle';
import {Platform} from "@ionic/angular";
import {AdMobService} from "./services/ad-mob.service";
import {CoreFunctionsService} from "./services/core-functions.service";
import {Network} from "@capacitor/network";
import { StatusBar, Style } from '@capacitor/status-bar';
import {SettingsService} from "./services/settings.service";
import {WebUpdateService} from "./services/web-update.service";

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(private readonly adMobService: AdMobService,
              private readonly coreFunctions: CoreFunctionsService,
              private readonly settingsService: SettingsService,
              private readonly webUpdateService: WebUpdateService,
              private platform: Platform) {
  }

  async ngOnInit() {
    await this.platform.ready().then(async () => {
      if(this.settingsService.IsNative()) {
          if (this.settingsService.ShowAds()) {
              await this.adMobService.initialize();
              await StatusBar.setStyle({style: Style.Dark});
              await StatusBar.setBackgroundColor({color: '#FF9944'});
          }
      }else{
          this.webUpdateService.startUpdateService();
          this.webUpdateService.checkForUpdates();
      }

      Network.addListener('networkStatusChange', async status => {
        if (!status.connected) {
          await this.coreFunctions.showAlert("You must have an internet connection to continue");
        }
      });
    });
  }
}


