import {Route, Router, UrlSegment, UrlTree} from '@angular/router';
import { ValidateTokenRequest } from '../api/models/Authentication/ValidateTokenRequest';
import { Preferences } from '@capacitor/preferences';
import {Observable} from "rxjs";
import {AuthenticationApiService} from "../api/services/authentication-api.service";
import {Injectable} from "@angular/core";

import {settings} from "../enums/settings";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {

  constructor(private authenticationApi: AuthenticationApiService,
              private router: Router) {
  }

  canMatch(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return Preferences.get({key: settings.BearerToken}).then(bearerToken => {
      return Preferences.get({key: settings.EmailAddress}).then(async emailAddress => {
        if (bearerToken.value && emailAddress.value) {
          let model: ValidateTokenRequest = {
            token: bearerToken.value,
            emailAddress: emailAddress.value
          };

          return this.authenticationApi.validateToken(model).then(async result => {
            if (result) {
              await Preferences.set({key: settings.BearerToken, value: result.refreshedToken});
              return true;
            } else {
              await Preferences.set({key: settings.EmailAddress, value: ''});
              await Preferences.set({key: settings.BearerToken, value: ''});
              await this.router.navigateByUrl('/login');
              return false;
            }
          }).catch(async e => {
            console.log(e);
            await Preferences.set({key: settings.EmailAddress, value: ''});
            await Preferences.set({key: settings.BearerToken, value: ''});
            await this.router.navigateByUrl('/login');

            return false;
          });
        }

        await Preferences.set({key: settings.EmailAddress, value: ''});
        await Preferences.set({key: settings.BearerToken, value: ''});
        await this.router.navigateByUrl('/login');
        return false;
      });
    });
  };
}
