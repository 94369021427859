import {Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from "@angular/service-worker";
import {filter, interval, map} from "rxjs";
import {AlertController} from "@ionic/angular";
import {SettingsService} from "./settings.service";

@Injectable({
  providedIn: 'root'
})
export class WebUpdateService{

  constructor(public readonly swUpdate: SwUpdate,
              public readonly settingsService: SettingsService,
              public readonly alertCtrl: AlertController) {  }

  public startUpdateService()
  {
      if (this.swUpdate.isEnabled && this.settingsService.UpdatePolling() > 0) {
          interval(this.settingsService.UpdatePolling()).subscribe(() => this.swUpdate.checkForUpdate().then(() => console.log('Checking For Updates...')));
      }
  }
  public checkForUpdates() {
    this.swUpdate.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))).subscribe(async evt => {
      await this.promptUser();
    });
  }

  async promptUser() {
    console.log('Updating to new Version');

    const alert = await this.alertCtrl.create({
      header: 'Update Required',
      message: 'Sweat and Sacrifice has been updated to add more features and possibly fix some issues. The application will automatically update when you close this pop up or restart the application.',
      buttons: ['Ok']
    });

    await alert.present();
    await alert.onDidDismiss();
    await this.swUpdate.activateUpdate();
    window.location.reload();
  }

}
