import {NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NotificationService } from "./services/notification.service";
import { CoreFunctionsService } from "./services/core-functions.service";
import {SettingsService} from "./services/settings.service";


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, ZXingScannerModule, IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }), AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: 'registerWhenStable:30000'})],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  constructor(private readonly platform: Platform,
              private readonly notificationService: NotificationService,
              private readonly settingsService: SettingsService,
              private readonly coreFunctions: CoreFunctionsService) {

    this.platform.ready().then((readySource: any) => {
      if(settingsService.AllowPush())
      {
        if(settingsService.IsNative())
        {
          notificationService.initPushNotifications();
        }
      }
    });
  }
}
