import {Injectable} from '@angular/core';
import {Preferences} from "@capacitor/preferences";
import {settings} from "../enums/settings";
import {CoreFunctionsService} from "./core-functions.service";
import {ErrorModel} from "../api/models/Misc/ErrorModel";
import {AuthenticateUserResponse} from "../api/models/Authentication/AuthenticateUserResponse";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private _production: boolean = false;
  private _baseUrl: string = '';
  private _showAds: boolean = false;
  private _allowPush: boolean = false;
  private _iosAdUnit: string = '';
  private _androidAdUnit: string = '';
  private _updatePolling: number = 100000;
  private _vapidPublic: string = '';
  private _isNative: boolean = false;

  constructor(private readonly coreFunctions: CoreFunctionsService) {
    this.readConfig();
  }


  private async readConfig()
  {
    let response = await fetch('./appsettings.json', {method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json' }});

    if(!response.ok)
    {
      throw new Error("Error Loading Config File.");
    }else{

      let jsonResponse = await response.json();

      this._production = jsonResponse.production;
      this._baseUrl = jsonResponse.baseUrl;
      this._showAds = jsonResponse.showAds;
      this._allowPush = jsonResponse.allowPush;
      this._iosAdUnit = jsonResponse.iosAdUnit;
      this._androidAdUnit = jsonResponse.androidAdUnit;
      this._updatePolling = jsonResponse.updatePolling;
      this._vapidPublic = jsonResponse.vapidPublic;
      this._isNative = jsonResponse.isNative;
    }
  }

  public Production = () => this._production;
  public BaseUrl = () => this._baseUrl;
  public ShowAds = () => this._showAds;
  public AllowPush = () => this._allowPush;
  public iOSAdUnit = () => this._iosAdUnit;
  public AndroidAdUnit = () => this._androidAdUnit;
  public UpdatePolling = () => this._updatePolling;
  public VapidPublicKey = () => this._vapidPublic;
  public IsNative = () => this._isNative;

  public async TestPopUpSeen(value: boolean | undefined = undefined)
  {
    if(value)
    {
      await Preferences.set({key: settings.TestPopupSeen, value: value ? "true" : "false" });
      return;
    }else{
      let result =  await Preferences.get({key: settings.TestPopupSeen });
      return result.value == 'true';
    }
  }

  public async UseDefaultMetaTags(value: boolean | undefined = undefined) : Promise<boolean>
  {
    if(value)
    {
      await Preferences.set({key: settings.UseDefaultMetaTags, value: value ? "true" : "false" });
      return false;
    }else{
      let result =  await Preferences.get({key: settings.UseDefaultMetaTags });

      if(result.value === null)
      {
        await this.coreFunctions.logUserOut();
        return false;
      }
      else {
        return result.value == 'true';
      }
    }
  }

  public async BearerToken(value: string | undefined = undefined) : Promise<string>
  {
    if(value)
    {
      await Preferences.set({key: settings.BearerToken, value: value ? value : '' });
      return '';
    }else{
      let result =  await Preferences.get({key: settings.BearerToken });
      return result.value ?? '';
    }
  }

  public async UserId(value: string | undefined = undefined) : Promise<string>
  {
    if(value)
    {
      await Preferences.set({key: settings.UserId, value: value ? value : '' });
      return '';
    }else{
      let result =  await Preferences.get({key: settings.UserId });

      if(result.value === null)
      {
        await this.coreFunctions.logUserOut();
        return '';
      }
      else{
        return result.value;
      }
    }
  }

  public async EmailAddress(value: string | undefined = undefined) : Promise<string>
  {
    if(value)
    {
      await Preferences.set({key: settings.EmailAddress, value: value ? value : '' });
      return '';
    }else{
      let result =  await Preferences.get({key: settings.EmailAddress });

      if(result.value === null)
      {
        await this.coreFunctions.logUserOut();
        return '';
      }
      else{
        return result.value;
      }
    }
  }

  public async WebPushAuth(value: string | undefined = undefined)
  {
    if(value)
    {
      await Preferences.set({key: settings.WebPushAuth, value: value ? value : '' });
      return;
    }else{
      let result =  await Preferences.get({key: settings.WebPushAuth });

      return result.value;
    }
  }

  public async WebPushPushKey(value: string | undefined = undefined)
  {
    if(value)
    {
      await Preferences.set({key: settings.WebPushPushKey, value: value ? value : '' });
      return;
    }else{
      let result =  await Preferences.get({key: settings.WebPushPushKey });

      return result.value;
    }
  }

  public async WebPushEndpoint(value: string | undefined = undefined)
  {
    if(value)
    {
      await Preferences.set({key: settings.WebPushEndpoint, value: value ? value : '' });
      return;
    }else{
      let result =  await Preferences.get({key: settings.WebPushEndpoint });

      return result.value;
    }
  }

  public async PushToken(value: string | undefined = undefined)
  {
    if(value)
    {
      await Preferences.set({key: settings.PushToken, value: value ? value : '' });
      return;
    }else{
      let result =  await Preferences.get({key: settings.PushToken });

      return result.value;
    }
  }

}



