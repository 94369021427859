import {Injectable} from '@angular/core';
import {AuthenticateUserRequest} from '../models/Authentication/AuthenticateUserRequest';
import {AuthenticateUserResponse} from '../models/Authentication/AuthenticateUserResponse';
import {ForgotPasswordRequest} from '../models/Authentication/ForgotPasswordRequest';
import {RegisterUserRequest} from '../models/Authentication/RegisterUserRequest';
import {ResetPasswordRequest} from '../models/Authentication/ResetPasswordRequest';
import {ValidateTokenRequest} from '../models/Authentication/ValidateTokenRequest';
import {ValidateTokenResponse} from '../models/Authentication/ValidateTokenResponse';
import {ErrorModel} from '../models/Misc/ErrorModel';
import {SettingsService} from "../../services/settings.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationApiService {

  constructor(private readonly settingsService: SettingsService) { }

  async authenticateUser(model: AuthenticateUserRequest)
  {
    let apiUrl = this.settingsService.BaseUrl() + '/v1/Authentication';

    if(!this.settingsService.Production())
    {
      console.log('API POST: ' + apiUrl);
      console.log('MODEL: ' + JSON.stringify(model));
    }

    try{
      let response = await fetch(apiUrl, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(model)});

      if(!response.ok)
      {
        if(response.status == 400)
        {
          return false;
        }

        if(response.status == 500)
        {
          throw new Error("An unexpected error has occured.");
        }

        let jsonError = await response.json();

        throw {
          errors: jsonError.errors,
          type: jsonError.type,
          title: jsonError.title,
          status: jsonError.status,
          traceId: jsonError.traceId
        };
      }else{

        let jsonResponse = await response.json();

        let result: AuthenticateUserResponse = {
          userId: jsonResponse.userId,
          emailAddress: jsonResponse.emailAddress,
          token: jsonResponse.token,
          expiryDate: jsonResponse.expiryDate
        };

        return result;
      }
    }
    catch(e)
    {
      throw e;
    }
  }

  async validateToken(model: ValidateTokenRequest)
  {
    let apiUrl = this.settingsService.BaseUrl() + '/v1/authentication/validate';

    if(!this.settingsService.Production())
    {
      console.log('API POST: ' + apiUrl);
      console.log('MODEL: ' + JSON.stringify(model));
    }

    try{
      let response = await fetch(apiUrl, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(model)});

      if(!response.ok)
      {
        if(response.status == 400)
        {
          return false;
        }

        if(response.status == 500)
        {
          throw new Error("An unexpected error has occured.");
        }

        let jsonError = await response.json();

        let error: ErrorModel = {
            errors: jsonError.errors,
            type: jsonError.type,
            title: jsonError.title,
            status: jsonError.status,
            traceId: jsonError.traceId
        }

        throw error;
      }else{

        let jsonResponse = await response.json();

        let result: ValidateTokenResponse = {
          userId: jsonResponse.userId,
          expiryDate: jsonResponse.expiryDate,
          refreshedToken: jsonResponse.refreshedToken,
          isValid: jsonResponse.isValid
        };

        return result;
      }
    }
    catch(e)
    {
      throw e;
    }
  }

  async forgotPassword(model: ForgotPasswordRequest)
  {
    let apiUrl = this.settingsService.BaseUrl() + '/v1/authentication/forgotpassword';

    if(!this.settingsService.Production())
    {
      console.log('API POST: ' + apiUrl);
      console.log('MODEL: ' + JSON.stringify(model));
    }

    try{
      let response = await fetch(apiUrl, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(model)});

      if(!response.ok)
      {
        if(response.status == 400)
        {
          return false;
        }

        if(response.status == 500)
        {
          throw new Error("An unexpected error has occured.");
        }

        let jsonError = await response.json();

        let error: ErrorModel = {
            errors: jsonError.errors,
            type: jsonError.type,
            title: jsonError.title,
            status: jsonError.status,
            traceId: jsonError.traceId
        }

        throw error;
      }else{
        return true;
      }
    }
    catch(e)
    {
      throw e;
    }
  }

  async resetPassword(model: ResetPasswordRequest)
  {
    let apiUrl = this.settingsService.BaseUrl() + '/v1/authentication/resetpassword';

    if(!this.settingsService.Production())
    {
      console.log('API POST: ' + apiUrl);
      console.log('MODEL: ' + JSON.stringify(model));
    }

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    }).then(response => {
      if (response.ok) {
        return response.json();
      }

      if(response.status == 500)
      {
        throw new Error("An unexpected error has occured.");
      }

      return response.json().then(errorMessage => {

        let error: ErrorModel = {
          errors: errorMessage.errors,
          type: errorMessage.type,
          title: errorMessage.title,
          status: errorMessage.status,
          traceId: errorMessage.traceId
        }

        throw error;
      });
    }).catch(e => {
      throw e;

    });

    return false;
  }

  async register(model: RegisterUserRequest)
  {
    let apiUrl = this.settingsService.BaseUrl() + '/v1/authentication/register';

    if(!this.settingsService.Production())
    {
      console.log('API POST: ' + apiUrl);
      console.log('MODEL: ' + JSON.stringify(model));
    }

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    }).then(response => {
      if (response.ok) {
        return true;
      }

      return false;

      if(response.status == 500)
      {
        throw new Error("An unexpected error has occured.");
      }

      return response.json().then(errorMessage => {

        let error: ErrorModel = {
          errors: errorMessage.errors,
          type: errorMessage.type,
          title: errorMessage.title,
          status: errorMessage.status,
          traceId: errorMessage.traceId
        }

        throw error;
      });
    }).catch(e => {
      throw e;
    });
  }
}
