export enum settings {
  BearerToken = "BearerToken",
  EmailAddress = "EmailAddress",
  TokenExpiryDate = "TokenExpiryDate",
  UserId = "UserId",
  VapidKey = "VapidPublic",
  UseDefaultMetaTags = "UseDefaultMetaTags",
  TestPopupSeen = "TestPopupSeen",
  PushToken = "PushToken",
  WebPushEndpoint = "WebPushEndpoint",
  WebPushPushKey = "WebPushPushKey",
  WebPushAuth = "WebPushAuth",

}
